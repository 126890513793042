<template>
  <div class="form-settings">
    <div class="form-settings__element form-settings__modal-opts">
      <modal-form-settings />
    </div>
    <div class="form-settings__element form-settings__modal-form">
      <modal-form />
    </div>
  </div>
</template>

<script>
import modalForm from '../components/modal-form/modal-form';
import modalFormSettings from '../components/modal-form/modal-form-settings'

export default {
  name: 'form-settings',
  data: function () {
    return {}
  },
  components: {
    'modal-form': modalForm,
    'modal-form-settings': modalFormSettings
  },
}
</script>

<style lang="scss">
.form-settings {
  display: flex;
  width: 100%;
  height: 100%;

  &__element {
    width: 50%;
    height: 100%;

    &:not(:last-child) {
      border-right: 2px solid var(--color-border);
    }
  }

  &__modal-form {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--color-border);
  }
}
</style>
