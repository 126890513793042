<template>
  <div class="modal-form-settings__wrapper">
    <form @submit.prevent="handleForm" class="modal-form-settings">
      <div class="modal-form-settings__content">
        <div class="modal-form-settings__title">Настройка внешнего вида формы</div>
        <app-accordion is-open-props="true">
          <template v-slot:header>Общие настройки</template>
          <template v-slot:body>
            <div v-if="settingsForm.overLay" class="modal-form-settings__box">

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Фоновая заливка</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="color" v-model="settingsForm.overLay.background" name="background">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Прозрачность фоновой заливки</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.overLay.opacity" max="100" name="background">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Позиционирование по горизонтали</div>
                <div class="modal-form-settings__field-body modal-form-settings__field-body_radio">
                  <label>
                    <input @input="changeFormSettings" type="radio" name="horizontal" value="left" v-model="settingsForm.overLay.horizontal">
                    <span>Слева</span>
                  </label>
                  <label>
                    <input @input="changeFormSettings" type="radio" name="horizontal" value="center" v-model="settingsForm.overLay.horizontal">
                    <span>По центру</span>
                  </label>
                  <label>
                    <input @input="changeFormSettings" type="radio" name="horizontal" value="right" v-model="settingsForm.overLay.horizontal">
                    <span>Справа</span>
                  </label>
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Позиционирование по вертикали</div>
                <div class="modal-form-settings__field-body  modal-form-settings__field-body_radio">
                  <label>
                    <input @input="changeFormSettings" type="radio" name="vertical" value="left" v-model="settingsForm.overLay.vertical">
                    <span>Сверху</span>
                  </label>
                  <label>
                    <input @input="changeFormSettings" type="radio" name="vertical" value="center" v-model="settingsForm.overLay.vertical">
                    <span>По центру</span>
                  </label>
                  <label>
                    <input @input="changeFormSettings" type="radio" name="vertical" value="right" v-model="settingsForm.overLay.vertical">
                    <span>Снизу</span>
                  </label>
                </div>
              </div>



            </div>
          </template>
        </app-accordion>
        <app-accordion>
          <template v-slot:header>Настройки формы</template>
          <template v-slot:body>
            <div v-if="settingsForm.form" class="modal-form-settings__box">

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Скругление краев формы</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.form.borderRadius" max="60" name="borderRadius">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Внутренние отступы слева/справа</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.form.paddingWidth" max="50" name="paddingWidth">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Внутренние отступы вверх/вниз</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.form.paddingHeight" max="50" name="paddingHeight">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Максимальная ширина</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.form.maxWidth" max="1000" name="maxWidth">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Фоновая заливка</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="color" v-model="settingsForm.form.background" name="background">
                </div>
              </div>

            </div>
          </template>
        </app-accordion>
        <app-accordion>
          <template v-slot:header>Заголовок</template>
          <template v-slot:body>
            <div v-if="settingsForm.title" class="modal-form-settings__box">

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Заголовок формы</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="text" v-model="settingsForm.title.text" name="text">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Жирность текста</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.title.fontWeight" min="100" max="900" step="100" name="fontWeight">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Размер текста</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.title.fontSize" min="14" max="30" name="fontSize">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Отступ снизу</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.title.marginBottom" max="30" name="marginBottom">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Цвет текста</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="color" v-model="settingsForm.title.color" name="color">
                </div>
              </div>

            </div>

          </template>
        </app-accordion>
        <app-accordion>
          <template v-slot:header>Кнопка закрытия (крестик)</template>
          <template v-slot:body>
            <div v-if="settingsForm.closeIcon" class="modal-form-settings__box">
              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Отступ справа</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.closeIcon.rightOffset" max="100" name="text">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Отступ сверху</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.closeIcon.topOffset" max="100" name="text">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Иконка</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="text" v-model="settingsForm.closeIcon.icon" title="Укажите ссылку на изображение чтобы заменить стандартное" placeholder="Укажите ссылку на изображение чтобы заменить стандартное" name="fontSize">
                </div>
              </div>

            </div>

          </template>
        </app-accordion>
        <app-accordion>
          <template v-slot:header>Поле ввода</template>
          <template v-slot:body>
            <div v-if="settingsForm.fields" class="modal-form-settings__box">

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Текст заглушки</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="text" v-model="settingsForm.fields.email.placeholderText" name="text">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Цвет текста заглушки</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="color" v-model="settingsForm.fields.email.placeholderColor" name="text">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Цвет текста</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="color" v-model="settingsForm.fields.email.color" name="text">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Цвет рамки поля</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="color" v-model="settingsForm.fields.email.borderColor" name="borderColor">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Скругление углов</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.fields.email.borderRadius" max="50" name="borderRadius">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Высота</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.fields.email.height" max="100" name="height">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Внутренние отступы слева/справа</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.fields.email.paddingWidth" max="100" name="paddingWidth">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Отступ снизу</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.fields.email.marginBottom" max="30" name="marginBottom">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Размер текста</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.fields.email.fontSize" min="12" max="30" name="fontSize">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Жирность текста</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.fields.email.fontWeight" min="100" max="900" step="100" name="fontWeight">
                </div>
              </div>

            </div>

          </template>
        </app-accordion>

        <app-accordion>
          <template v-slot:header>Капча</template>
          <template v-slot:body>
            <div class="modal-form-settings__box">
              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Использовать капчу?</div>
                <div class="modal-form-settings__field-body">
                  <label>
                    <input @input="changeFormSettings" type="checkbox" v-model="settingsForm.captcha" value="1" name="captcha">
                    <span>Использовать защиту от роботов</span>
                  </label>
                </div>
              </div>
            </div>
          </template>
        </app-accordion>

        <app-accordion>
          <template v-slot:header>Кнопка</template>
          <template v-slot:body>
            <div v-if="settingsForm.button" class="modal-form-settings__box">

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Текст кнопки</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="text" v-model="settingsForm.button.text" name="text">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Цвет текста</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="color" v-model="settingsForm.button.color" name="color">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Цвет фона</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="color" v-model="settingsForm.button.background" name="background">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Цвет фона при наведении курсора</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="color" v-model="settingsForm.button.backgroundHover" name="backgroundHover">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Скругление краев</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.button.borderRadius" min="0" max="50" name="fontSize">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Размер текста</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.button.fontSize" min="12" max="30" name="fontSize">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Жирность текста</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.button.fontWeight" max="900" step="100" name="fontWeight">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Ширина в %</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.button.width" max="100" name="width">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Отступ снизу</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.button.marginBottom" max="30" name="marginBottom">
                </div>
              </div>


            </div>

          </template>
        </app-accordion>
        <app-accordion>
          <template v-slot:header>Описание</template>
          <template v-slot:body>
            <div v-if="settingsForm.description" class="modal-form-settings__box">

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Текст описания</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="text" v-model="settingsForm.description.text" name="text">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Цвет описания</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="color" v-model="settingsForm.description.color" name="color">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Размер текста</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.description.fontSize" min="12" max="30" name="fontSize">
                </div>
              </div>

              <div class="modal-form-settings__field">
                <div class="modal-form-settings__field-title">Жирность текста</div>
                <div class="modal-form-settings__field-body">
                  <input @input="changeFormSettings" type="range" v-model="settingsForm.description.fontWeight" max="900" step="100" name="fontWeight">
                </div>
              </div>

            </div>

          </template>
        </app-accordion>

      </div>
      <div class="modal-form-settings__comment">
        ВНИМАНИЕ! Внесенные изменения будут отображены только после сохранения.
      </div>
      <div class="modal-form-settings__button">
        <app-button value="Сохранить" height="50" width="100%" type="submit"/>
      </div>

    </form>
    <app-message className="content-message" ref="app-message" />
  </div>
</template>

<script>
import appMessage from '../base-ui/app-message';
import appAccordion from '../base-ui/app-accordion';
import appButton from '../base-ui/app-button';

export default {
  name: 'modal-form-settings',
  data: function () {
    return {
      data: {}
    }
  },
  components: {
    'app-accordion': appAccordion,
    'app-message': appMessage,
    'app-button': appButton
  },
  computed: {
    settingsForm: function () {
      return this.$store.state.modalForm.settings || {};
    },
    insalesId: function () {
      return this.$store.state.account.accountData.insales_id;
    }
  },
  methods: {
    createMessage: function (opts) {
      this.$refs['app-message'].open({
        type: opts.type,
        message: opts.message,
      })
    },
    sendForm: function () {
      return this.$store.dispatch('updateFormSettings', { body: this.settingsForm })
          .then(response => {
            if (response && response.statusCode && response.statusCode === 401) {
              return this.$store.dispatch('logoutAccount')
            }

            this.createMessage({
              type: 'success',
              message: 'Данные успешно сохранены'
            })
          })
          .catch((e) => {
            this.createMessage({
              type: 'warning',
              message: 'Что-то пошло не так'
            })

            throw e;
          });
    },
    handleForm: function(e) {
      e.preventDefault();
      this.sendForm();
    },
    changeFormSettings: async function () {
      setTimeout(() => {
        const updatedData = JSON.parse(JSON.stringify(this.settingsForm));
        this.$store.commit('SET_SETTINGS', updatedData);
      }, 0 )
    },
    getSettingsFormData: function () {
      this.$store.dispatch('getFormSettings')
        .then(response => {
          if (response && response.statusCode && response.statusCode === 401) {
            return this.$store.dispatch('logoutAccount')
          }

          return this.data = response;
        })
        .catch(() => {
          this.createMessage({
            type: 'warning',
            message: 'Что-то пошло не так. Обновите страницу'
          })
        });
    },
    getData: function () {
      if (! this.$store.state.modalForm.settings.form) {
        this.getSettingsFormData()
      }
    }
  },
  mounted() {
    this.getData();
  }
}
</script>

<style lang="scss">
.modal-form-settings {
  position: relative;
  height: 100%;

  &__wrapper {
    height: 100%;
  }

  &__content {
    padding: 30px;
    overflow-y: scroll;
    height: calc(100% - 120px);
  }

  &__title {
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;

    color: #000000;

    margin-bottom: 20px;
  }

  &__comment {
    position: absolute;
    bottom: 90px;
    left: 30px;
    z-index: 5;
    width: calc(100% - 60px);

    font-size: 13px;
    font-weight: 600;

    background-color: var();
  }

  &__button {
    position: absolute;
    bottom: 30px;
    left: 30px;
    z-index: 5;
    width: calc(100% - 60px);
  }

  & input[type="text"] {
    width: 100%;
    border-radius: 30px;
    height: 50px;
    border: 1px solid var(--color-border);
    padding: 0 30px;
  }

  &__field:not(:last-child) {
    margin-bottom: 30px;
  }

  &__field-body {
    display: flex;
    align-items: center;

    &_radio {
      display: flex;
      justify-content: space-between;
      max-width: 360px;
    }
  }

  &__field-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #747988;
    margin-bottom: 10px;
  }
}
</style>
