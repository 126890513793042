<template>
  <div class="modal-form" v-if="modalHTML">
    <div class="modal-form__wrapper" v-html="modalHTML"></div>
  </div>
</template>

<script>
export default {
  name: 'modal-form',
  computed: {
    modalHTML: function () {
      return this.$store.state.modalForm.html || '';
    },
    insalesId: function () {
      return this.$store.state.account.accountData.insales_id;
    }
  },

  methods: {
    getModalBuild() {
      if (! this.insalesId) {
        return;
      }

      this.$store.dispatch('getHTMLForm', { insalesId: this.insalesId })
        .then(response => {
          if (response && response.statusCode && response.statusCode === 401) {
            return this.$store.dispatch('logoutAccount')
          }

          return response;
        })
        .catch(error => {
          return error;
        })
    },
    getUserAccountData: async function () {
      // Если нет, то делаем запрос
      if (! Object.keys(this.$store.state.account.accountData).length) {
        this.$store.dispatch('getAccountData');
      }
    },
    getData: function () {
      this.getUserAccountData()
    }
  },
  mounted() {
    this.getData();
    this.getModalBuild()
  },
  watch: {
    insalesId: function () {
      this.getModalBuild()
    }
  }
}
</script>

<style lang="scss">
.modal-form {
  width: 100%;
  height: 100%;

  &__wrapper {
    width: 100%;
    height: 100%;

    position: relative;
  }
}
.pnn-modal-form__wrapper {
  position: absolute !important;
}



.pnn-modal-form {
  font-family: 'Montserrat';
}


</style>
